import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import VSECSGEM from "./Product/VSECSGEM";
import VSPC from "./Product/VSPC";
import VDIS from "./Product/VDIS";
import VSUNDAY from "./Product/VSUNDAY";
import VMONDAY from "./Product/VMONDAY";
import VNOTIFIER from "./Product/VNOTIFIER";
import VPLC from "./Product/VPLC";
import VPARSER from "./Product/VPARSER";
import VGERBER from "./Product/VGERBER";
import VAUTOMOUSE from "./Product/VAUTOMOUSE";
import VMONITORING from "./Product/VMONITORING";
import VES from "./Product/VES";
import VINTERLOCK from "./Product/VINTERLOCK"
import VCLEANER from "./Product/VCLEANER";
function ProductV({match}) {
  const [pid, setPid] = useState("");

  useEffect(() => {
    setPid(match.params.pid);
    window.scrollTo(0, 0);
    AOS.init({
      easing: "ease-out-back",
      once: true
    })
  }, []);

  function MenuItem({ title }) {
    return (
      <div className="product-nav__item-v" onClick={() => setPid(title)}>
        <div className="p2">{title}</div>
      </div>
    )
  }

  const GetProduct = () => {
    if (pid == 'M-SECSGEM') {
      return (
        <VSECSGEM />
      );
    } else if (pid == 'M-SPC') {
      return (
        <VSPC />
      );
    } else if (pid == 'M-DIS') {
      return (
        <VDIS />
      );
    } else if (pid == 'M-SUNDAY') {
      return (
        <VSUNDAY />
      );
    } else if (pid == 'M-MONDAY') {
      return (
        <VMONDAY />
      );
    } else if (pid == 'M-NOTIFIER') {
      return (
        <VNOTIFIER />
      );
    } else if (pid == 'M-PLC') {
      return (
        <VPLC />
      );
    } else if (pid == 'M-PARSER') {
      return (
        <VPARSER />
      );
    } else if (pid == 'M-GERBER') {
      return (
        <VGERBER />
      );
    } else if (pid == 'M-AUTOMOUSE') {
      return (
        <VAUTOMOUSE />
      );
    } else if (pid == 'M-MONITORING') {
      return (
        <VMONITORING />
      );
    } else if (pid == 'M-ES') {
      return (
        <VES />
      );
    } else if (pid == 'M-INTERLOCK') {
      return (
        <VINTERLOCK />
      );
    } else if (pid == 'M-CLEANER') {
      return (
        <VCLEANER />
      );
    } else {
      return (
        <VSECSGEM />
      );
    }
  }

  return (
    <>
      <div className="section">
        <div className="section__product-header" data-a os="fade-down" data-aos-delay="50" data-aos-duration="1000">
          <div className="product-nav">
            <MenuItem title="M-SECSGEM"></MenuItem>
            <MenuItem title="M-SPC"></MenuItem>
            <MenuItem title="M-DIS"></MenuItem>
            <MenuItem title="M-SUNDAY"></MenuItem>
            <MenuItem title="M-MONDAY"></MenuItem>
            <MenuItem title="M-NOTIFIER"></MenuItem>
            <MenuItem title="M-PLC"></MenuItem>
            <MenuItem title="M-PARSER"></MenuItem>
            <MenuItem title="M-GERBER"></MenuItem>
            <MenuItem title="M-AUTOMOUSE"></MenuItem>
            <MenuItem title="M-MONITORING"></MenuItem>
            <MenuItem title="M-ES"></MenuItem>
            <MenuItem title="M-INTERLOCK"></MenuItem>
            <MenuItem title="M-CLEANER"></MenuItem>

            {/* <MenuItem title="M-SECSGEM_DIB"></MenuItem>
            <MenuItem title="M-SECSGEM_DISPENSER"></MenuItem>
            <MenuItem title="M-SECSGEM_LOADER"></MenuItem>
            <MenuItem title="M-SECSGEM_OVEN"></MenuItem>
            <MenuItem title="M-SECSGEM_REHM"></MenuItem>
            <MenuItem title="M-ATDRIVER_SELECTIVE"></MenuItem>
            <MenuItem title="M-ATDRIVER_DISPENSER"></MenuItem>
            <MenuItem title="M-HMT"></MenuItem>

            <MenuItem title="M-ATDRIVER_UV"></MenuItem>
            <MenuItem title="M-ATDRIVER_REFLOW"></MenuItem>
            <MenuItem title="M-SECSGEM_OA"></MenuItem>
            <MenuItem title="M-SECSGEM_HEADER"></MenuItem>

            <MenuItem title="M-FILE_MANAGER"></MenuItem>
            <MenuItem title="M-FUJI_MOUNTER"></MenuItem>
            <MenuItem title="M-LOGGER_SELECTIVE"></MenuItem>
            <MenuItem title="M-PARSER_SELECT"></MenuItem>
            <MenuItem title="M-PARSER-XRAY"></MenuItem>
            <MenuItem title="M-PLC_STORAGE"></MenuItem>
            <MenuItem title="M-SECSGEM_OA"></MenuItem>
            <MenuItem title="M-XML_AOI"></MenuItem> */}


          </div>
        </div>
        <div className="section__product-info">
          <div className="product-area">
            {GetProduct()}
          </div>
        </div>
      </div>
    </>
  )
}
export default ProductV;
