import './App.scss';
import Header from './Common/Header';
import Footer from './Common/Footer';
import { BrowserRouter, Route } from 'react-router-dom';
import MainSection from './Component/MainSection';
import ProductX from './Component/ProductX';
import ProductV from './Component/ProductV';
import Experience from './Component/Experience';
import Partner from './Component/Partner';
import History from './Component/History';
import Contact from './Component/Contact';
import Sidebar from './Common/Sidebar';
import IMG from './assets/images/hType4.png';
import { useEffect, useState } from 'react';

// 모바일, PC 확인
import { useMediaQuery } from 'react-responsive';

function App() {
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  if (width > 768) {
    return (
      <BrowserRouter>
        <div className="App">
          <Sidebar />
          <div className="body">
            <div className="wrap">
              <header className="header">
                <Header />
              </header>
              <div id="main">
                <Route exact path="/" component={MainSection} />
                <Route path="/product/x/:pid" component={ProductX} />
                <Route path="/product/v/:pid" component={ProductV} />
                <Route path="/experience" component={Experience} />
                <Route path="/partner" component={Partner} />
                <Route path="/history" component={History} />
                <Route path="/contact" component={Contact} />
              </div>
              <section id={isDesktop ? "f_contact" : "f_contact_mobile"}>
                <div className='f_footer'>
                  <div className='f_main'>
                    <div className={isDesktop ? "f_list" : "f_list_mobile"}>
                      <h4>Contact Info</h4>
                      <ul>
                        <li>
                          <a>경기도 성남시 중원구 제일로 43 2층 204호</a>
                        </li>
                        <li>
                          <a>204-2F, 43, Jeil-ro, Jungwon-gu, Seongnam-si, Gyeonggi-do, Republic of Korea</a>
                        </li>
                        {/* <li><a><br/></a></li> */}
                        <li>
                          <a>Tel : +82 31-524-9901</a>
                        </li>
                        <li>
                          <a>Fax : +82 31-524-9902</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              {/* <footer>
                <div className="footer">
                  <div className="block">Tel</div>
                  <div className="text">070-4617-3833</div>
                  <div className="block">Fax</div>
                  <div className="text">070-8290-0066</div>
                  <div className="block">Address</div>
                  <div className="text">경기도 성남시 분당구 성남대로 916번길 11, 6층 503호(야탑동 글라스타워)</div>
                  <div className="copyright">© Copyright 2018 www.MYPP.co.kr. All Rights Reserved.</div>
                </div>
              </footer> */}
            </div>
          </div>
        </div>
      </BrowserRouter>
    );
  } else {

    return (
      <BrowserRouter>
        <div className="App">
          <Sidebar />
          <div className="m-body">
            <header className="m-header">
              <img className="m-logo" src={IMG} alt=""></img>
            </header>
            <>
              <Route exact path="/" component={MainSection} />
              <Route path="/product/x/:pid" component={ProductX} />
              <Route path="/product/v/:pid" component={ProductV} />
              <Route path="/experience" component={Experience} />
              <Route path="/partner" component={Partner} />
              <Route path="/history" component={History} />
              <Route path="/contact" component={Contact} />
            </>
            <section id="f_contact">
                <div className='f_footer'>
                  <div className='f_main'>
                    <div className='f_list'>
                      <h4>Contact Info</h4>
                      <ul>
                        <li>
                          <a>경기도 성남시 중원구 제일로 43 2층 204호</a>
                        </li>
                        <li>
                          <a>204-2F, 43, Jeil-ro, Jungwon-gu, Seongnam-si, Gyeonggi-do, Republic of Korea</a>
                        </li>
                        {/* <li><a><br/></a></li> */}
                        <li>
                          <a>Tel : +82 31-524-9901</a>
                        </li>
                        <li>
                          <a>Fax : +82 31-524-9902</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
